/**
 * Polyfill for native sticky positioning.
 * @see: https://github.com/wilddeer/stickyfill
 */

import Stickyfill from 'stickyfilljs';

export const enhancer = el => {
  Stickyfill.add(el);
};
