import { parseJson } from '@grrr/utils';

const composePopupHTML = (properties) => {
  let html = `<a href="${properties.link}" class="world-map__ranking-link">`;
  if (properties.ranking && properties.ranking !== 'null') {
    html += `<p class="world-map__ranking-label text-color--400">#${properties.ranking}</h3>`;
  }
  html += `<p class="world-map__ranking-title">${properties.title}</p>`;
  html += `<p class="world-map__ranking-location">${properties.country}</p>`;
  html += `</a>`;
  return html;
};

const composeGeoJsonFeatures = (locations) => {
  return locations.map(location => {
    return {
      type: 'Feature',
      properties: {
        title: location.title,
        country: location.place,
        ranking: location.ranking,
        link: location.link,
      },
      geometry: {
        type: "Point",
        coordinates: [location.lng, location.lat],
      },
    };
  });
};

export const enhancer = (container) => {
  const url = container.getAttribute('data-mapbox-js');
  if (!url) {
    console.warn('Missing Mapbox URL.');
    return;
  }

  const locations = parseJson(container.getAttribute('data-locations'));
  const mapboxStyle = parseJson(container.getAttribute('data-mapbox-style'));

  window.loadJS(url, () => {
    window.mapboxgl.accessToken = 'pk.eyJ1Ijoid29ybGRiYSIsImEiOiJjazFieDh6aTUwYXc1M2lvNnZwdWUyaDAxIn0.hvNlT95aX3xtGlbK78bNHA';
    const map = new window.mapboxgl.Map({
      container: 'world-map',
      style: mapboxStyle.style,
      center: [0.1, 0.1],
      zoom: 1,
      attributionControl: false,
    });

    map.scrollZoom.disable();

    map.addControl(new window.mapboxgl.AttributionControl({
      compact: true,
    }), 'top-left');

    map.addControl(new window.mapboxgl.NavigationControl({
      showCompass: false,
    }), 'bottom-right');

    map.on('load', () => {
      map.addSource('locations', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: composeGeoJsonFeatures(locations),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: 'markers',
        type: 'circle',
        source: 'locations',
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": mapboxStyle.marker_color, // Todo: this color should change depending on the benchmark
          "circle-radius": 12,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#FFFFFF",
        },
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: 'locations',
        filter: ["has", "point_count"],
        paint: {
          "circle-color": '#FFFFFF',
          "circle-radius": 27,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "locations",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-size": 19,
          "text-font": ["Open Sans Bold"],
        },
        paint: {
          "text-color": "#0B274B",
        },
      });

      map.on('click', 'markers', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the §copy being pointed to.
        while (Math.abs(e.lngLat - coordinates[0] > 180)) {
          coordinates[0] += e.lngLat > coordinates[0] ? 360 : -360;
        }

        new window.mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(composePopupHTML(e.features[0].properties))
          .setMaxWidth('275px')
          .addTo(map);
      });

      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });

        const clusterId = features[0].properties.cluster_id;
        map.getSource('locations').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        });
      });
    });
  });
};
