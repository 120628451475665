// TODO: Make it more responsive by using async functions
import { debounce } from "@grrr/utils";

const LIST_SELECTOR = ".js-list";
const ITEMS_SELECTOR = ".js-item";
const NO_RESULTS_SELECTOR = ".js-no-results";
const LISTING_COUNT_SELECTOR = ".js-listing-count";

const filterFunctions = {
  query(item, value) {
    return item.getAttribute("data-name").search(value.toLowerCase()) !== -1;
  },
  transAreas(item, selectedAreas) {
    if (!selectedAreas.length) {
      return true;
    }
    const itemAreas = item.getAttribute("data-trans-area").split(",");
    return selectedAreas.every((area) => itemAreas.includes(area));
  },
  valueChainSegments(item, selectedSegments) {
    if (!selectedSegments.length) {
      return true;
    }
    const itemSegments = item.getAttribute("data-vcs").split(",");
    return selectedSegments.some((segment) => itemSegments.includes(segment));
  },
  scorecardIndustries(item, selectedIndustries) {
    if (!selectedIndustries.length) {
      return true;
    }
    const industries = item
      .getAttribute("data-scorecard_industries")
      .split(",");
    return selectedIndustries.some((industry) => industries.includes(industry));
  },

  // @TODO Refactor with filterable-ranking-table.js to make this DRY, see: AC-525
  industry(item, value) {
    return value ? item.getAttribute("data-industry") === value : true;
  },
  country(item, value) {
    return value ? item.getAttribute("data-country") === value : true;
  },
  scorecardCountry(item, value) {
    return value ? item.getAttribute("data-scorecard_country") === value : true;
  },
  region(item, value) {
    return value ? item.getAttribute("data-region") === value : true;
  },
  scope(item, value) {
    return value ? item.getAttribute("data-scope") === value : true;
  },
  // @TODO: refactor this, so all climate benchmark use the same "extra" taxonomy, see AC-525
  companyType(item, value) {
    const companyTypes = item.getAttribute("data-company_type").split(",");
    return value ? companyTypes.includes(value) : true;
  },
  allies(item, value) {
    const scorecardAllies = item.getAttribute("data-allies").split(",");
    return value ? scorecardAllies.includes(value) : true;
  },
};

const CompanyListing = (container) => {
  const searchInput = container.querySelector('input[type="search"]');
  const transAreas = container.querySelectorAll(
    'button[data-filter="trans-area"]'
  );
  const industrySelect = container.querySelector(
    'select[data-filter="industry"]'
  );
  const countrySelect = container.querySelector(
    'select[data-filter="country"]'
  );
  const scorecardCountrySelect = container.querySelector(
    'select[data-filter="scorecard_country"]'
  );
  const regionSelect = container.querySelector('select[data-filter="region"]');
  const valueChainSegments = container.querySelectorAll(
    'button[data-filter="vcs"]'
  );
  const scorecardIndustries = container.querySelectorAll(
    'button[data-filter="scorecard_industries"]'
  );
  const scopeSelect = container.querySelector('select[data-filter="scope"]');
  const companyTypeSelect = container.querySelector('select[data-filter="company_type"]');
  const alliesSelect = container.querySelector('select[data-filter="allies"]');

  const listingCount = container.querySelector(LISTING_COUNT_SELECTOR);
  const noResults = container.querySelector(NO_RESULTS_SELECTOR);
  const listingContainer = container.querySelector(LIST_SELECTOR);

  const items = container.querySelectorAll(ITEMS_SELECTOR);
  const itemCount = items.length;

  const filters = {};

  const itemMatchesFilters = (item) => {
    return Object.entries(filters).every(([filterType, filterValue]) => {
      return filterFunctions[filterType](item, filterValue);
    });
  };

  const hideItem = (item) => item.setAttribute("hidden", "");
  const showItem = (item) => item.removeAttribute("hidden");

  const hideNoResults = () => noResults.setAttribute("hidden", "");
  const showNoResults = () => noResults.removeAttribute("hidden");

  const toggleButton = (button) => {
    button.setAttribute(
      "aria-pressed",
      !(button.getAttribute("aria-pressed") === "true")
    );
    button.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const updateListingCount = (amount) => {
    listingCount.innerHTML =
      amount === itemCount ? `${itemCount}` : `${amount} of ${itemCount}`;
  };

  const hideListing = () => {
    listingContainer.setAttribute("aria-hidden", "true");
  };

  const showListing = () => {
    listingContainer.setAttribute("aria-hidden", "false");
  };

  const scrollToListing = () => {
    container.scrollIntoView();
  };

  const moveToStartPosition = () => {
    const { top } = container.getBoundingClientRect();
    if (top > 0) {
      return;
    }
    document.documentElement.style.scrollBehavior = "auto";
    document.documentElement.scrollTop = top + window.pageYOffset;
    document.documentElement.style.scrollBehavior = "";
  };

  const refreshItems = () => {
    hideListing();
    const matchingItems = [...items].filter((item) => itemMatchesFilters(item));
    window.setTimeout(() => {
      [...items].map(hideItem);
      matchingItems.map(showItem);
      updateListingCount(matchingItems.length);
      moveToStartPosition();
      scrollToListing();
      if (!matchingItems.length) {
        showNoResults();
      } else {
        hideNoResults();
      }
      showListing();
    }, 300);
  };

  const queryChanged = (el) => (e) => {
    filters.query = el.value;
    refreshItems();
  };

  const transAreasChanged = (clickedArea, areas) => (e) => {
    toggleButton(clickedArea);
    filters.transAreas = [...areas]
      .filter((area) => area.getAttribute("aria-pressed") === "true")
      .map((area) => area.getAttribute("data-value"));
    refreshItems();
  };

  const valueChainSegmentsChanged = (clickedSegment, segments) => (e) => {
    toggleButton(clickedSegment);
    filters.valueChainSegments = [...segments]
      .filter((segment) => segment.getAttribute("aria-pressed") === "true")
      .map((segment) => segment.getAttribute("data-value"));
    refreshItems();
  };

  const scorecardIndustriesChanged = (clickedIndustry, industries) => (e) => {
    toggleButton(clickedIndustry);
    filters.scorecardIndustries = [...industries]
      .filter((industry) => industry.getAttribute("aria-pressed") === "true")
      .map((industry) => industry.getAttribute("data-value"));
    refreshItems();
  };

  const industryChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.industry = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const countryChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.country = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const scorecardCountryChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.scorecardCountry = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const regionChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.region = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const scopeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.scope = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const companyTypeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.companyType = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const alliesChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.allies = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const uncheckTransAreas = () => {
    [...transAreas].map((area) => area.setAttribute("aria-pressed", false));
  };

  const uncheckValueChainSegments = () => {
    [...valueChainSegments].map((area) =>
      area.setAttribute("aria-pressed", false)
    );
  };

  const checkTransArea = (transAreaName) => {
    const button = container.querySelector(
      `button[data-slug="${transAreaName}"]`
    );
    if (!button) {
      return;
    }
    button.click();
  };

  const checkValueChainSegment = (segmentName) => {
    const button = container.querySelector(
      `button[data-slug="${segmentName}"]`
    );
    if (!button) {
      return;
    }
    button.click();
  };

  const selectTransAreaByHash = (hash) => {
    const hashToCheck = "#transarea=";
    if (!hash.startsWith(hashToCheck)) {
      return;
    }
    const transArea = hash.substring(hashToCheck.length);
    uncheckTransAreas();
    checkTransArea(transArea);
  };

  const selectValueChainSegmentByHash = (hash) => {
    const hashToCheck = "#vcs=";
    if (!hash.startsWith(hashToCheck)) {
      return;
    }
    const segment = hash.substring(hashToCheck.length);
    uncheckValueChainSegments();
    checkValueChainSegment(segment);
  };

  const hashChangeHandler = (e) => {
    selectTransAreaByHash(window.location.hash);
    selectValueChainSegmentByHash(window.location.hash);
  };

  return {
    init() {
      searchInput.addEventListener(
        "input",
        debounce(queryChanged(searchInput), 300)
      );
      [...transAreas].map((area) =>
        area.addEventListener("click", transAreasChanged(area, transAreas))
      );
      [...valueChainSegments].map((segment) =>
        segment.addEventListener(
          "click",
          valueChainSegmentsChanged(segment, valueChainSegments)
        )
      );
      [...scorecardIndustries].map((industry) =>
        industry.addEventListener(
          "click",
          scorecardIndustriesChanged(industry, scorecardIndustries)
        )
      );
      if (industrySelect) {
        industrySelect.addEventListener(
          "change",
          industryChanged(industrySelect)
        );
      }
      if (countrySelect) {
        countrySelect.addEventListener("change", countryChanged(countrySelect));
      }
      if (scorecardCountrySelect) {
        scorecardCountrySelect.addEventListener(
          "change",
          scorecardCountryChanged(scorecardCountrySelect)
        );
      }
      if (regionSelect) {
        regionSelect.addEventListener("change", regionChanged(regionSelect));
      }
      if (scopeSelect) {
        scopeSelect.addEventListener("change", scopeChanged(scopeSelect));
      }
      if (companyTypeSelect) {
        companyTypeSelect.addEventListener("change", companyTypeChanged(companyTypeSelect));
      }
      if (alliesSelect) {
        alliesSelect.addEventListener("change", alliesChanged(alliesSelect));
      }
      selectTransAreaByHash(window.location.hash);
      selectValueChainSegmentByHash(window.location.hash);
      window.addEventListener("hashchange", hashChangeHandler);
    },
  };
};

export const enhancer = (container) => {
  const companyListing = CompanyListing(container);
  companyListing.init();
};
