const TRIGGERED_ATTRIBUTE = "data-triggered";
let previousScrollYPosition = 0;

function shouldAttributeUpdate(inView) {
  const scrollDirectionIsDown = window.scrollY > previousScrollYPosition;
  previousScrollYPosition = window.scrollY;

  /**
   * The "data-triggered" attribute should only update when:
   *  - You scroll down (scrollDirectionIsDown) and an item enters the screen (inView)
   *  - You scroll up (!scrollDirectionIsDown) and an item leaves the screen (!inView)
   *
   *  The new and old scrollDirection can be the same (previousScrollYPosition === window.scrollY)
   *  This can happen when you reverse your scroll direction in the middle of the image scroller.
   */
  if (
    (scrollDirectionIsDown && inView) ||
    (!scrollDirectionIsDown && !inView) ||
    window.scrollY === previousScrollYPosition
  ) {
    return true;
  }

  return false;
}

function containerCallback(entries) {
  entries.forEach((entry) => {
    entry.target.classList.toggle("is-fixed", entry.isIntersecting);
  });
}

function itemCallback(entries) {
  entries.forEach((entry) => {
    const entryContainer = entry.target.parentElement;

    if (entry.isIntersecting && shouldAttributeUpdate(entry.isIntersecting)) {
      entryContainer.setAttribute(TRIGGERED_ATTRIBUTE, true);
    }
    if (!entry.isIntersecting && shouldAttributeUpdate(entry.isIntersecting)) {
      entryContainer.setAttribute(TRIGGERED_ATTRIBUTE, false);
    }

    // If the image is above the center of the viewport on load TRIGGERED_ATTRIBUTE should be true
    if (entry.target.getBoundingClientRect().top < window.innerHeight / 2) {
      entryContainer.setAttribute(TRIGGERED_ATTRIBUTE, true);
    }
  });
}

function scrollToLocation(container) {
  const url = new URL(window.location.href);
  if (url.searchParams.has("keyFindings")) {
    container.querySelector(".js-kf-intro").scrollIntoView();
  }
}

export const enhancer = (container) => {
  const containerOptions = {
    rootMargin: "0px 0px -100%",
    threshold: 0,
  };
  const itemOptions = {
    // When an item gets to the middle of the screen it's triggered
    // If you want to adjust the rootMargin,
    // you also need to adjust the if statement on line 45
    rootMargin: "-49.9% 0% -49.9% 0%",
    threshold: 0.00001,
  };

  scrollToLocation(container);

  const containerObserver = new IntersectionObserver(
    containerCallback,
    containerOptions
  );
  const itemObserver = new IntersectionObserver(itemCallback, itemOptions);

  const imageScrollerTexts = container.querySelectorAll(
    ".image-scroller__item .image-scroller__content"
  );

  containerObserver.observe(container);
  imageScrollerTexts.forEach((item) => {
    itemObserver.observe(item);
  });
};
