import { debounce } from "@grrr/utils";

const LIST_SELECTOR = ".js-list";
const ITEMS_SELECTOR = ".js-item";
const NO_RESULTS_SELECTOR = ".js-no-results";
const LISTING_COUNT_SELECTOR = ".js-listing-count";

const filterFunctions = {
  query(item, value) {
    return item.getAttribute("data-name").search(value.toLowerCase()) !== -1;
  },
  transAreas(item, selectedAreas) {
    if (!selectedAreas.length) {
      return true;
    }
    const itemAreas = item.getAttribute("data-trans-area").split(",");
    return selectedAreas.some((area) => itemAreas.includes(area));
  },
  valueChainSegments(item, selectedSegments) {
    if (!selectedSegments.length) {
      return true;
    }
    const itemSegments = item.getAttribute("data-vcs").split(",");
    return selectedSegments.some((segment) => itemSegments.includes(segment));
  },
  benchmarkTopics(item, selected) {
    if (!selected.length) {
      return true;
    }
    const values = item.getAttribute("data-benchmark_topic").split(",");
    return selected.some((selectedValue) => values.includes(selectedValue));
  },
  industry(item, value) {
    return value ? item.getAttribute("data-industry") === value : true;
  },
  country(item, value) {
    return value ? item.getAttribute("data-country") === value : true;
  },
  stakeholderGroup(item, value) {
    if (!value.length) {
      return true;
    }
    const ids = item.getAttribute("data-stakeholder_group").split(",");
    return ids.includes(value);
  },
  newsType(item, value) {
    return value ? item.getAttribute("data-news_type") === value : true;
  },
  impactType(item, value) {
    return value ? item.getAttribute("data-impact_type") === value : true;
  },
  researchType(item, value) {
    return value ? item.getAttribute("data-research_type") === value : true;
  },
  team(item, value) {
    const teams = item.getAttribute("data-team").split(",");
    return value ? teams.includes(value) : true;
  },
  allies(item, value) {
    const scorecardAllies = item.getAttribute("data-allies").split(",");
    return value ? scorecardAllies.includes(value) : true;
  },

  region(item, value) {
    return value ? item.getAttribute("data-region") === value : true;
  },
};

const FilterableList = (container) => {
  // search
  const searchInput = container.querySelector('input[type="search"]');

  // single select
  const industrySelect = container.querySelector(
    'select[data-filter="industry"]'
  );
  const countrySelect = container.querySelector(
    'select[data-filter="country"]'
  );
  const stakeholderGroupSelect = container.querySelector(
    'select[data-filter="stakeholder_group"]'
  );
  const newsTypeSelect = container.querySelector(
    'select[data-filter="news_type"]'
  );
  const impactTypeSelect = container.querySelector(
    'select[data-filter="impact_type"]'
  );
  const researchTypeSelect = container.querySelector(
    'select[data-filter="research_type"]'
  );
  const teamSelect = container.querySelector('select[data-filter="team"]');
  const regionSelect = container.querySelector('select[data-filter="region"]');

  // multi select (collection of buttons)
  const transAreas = container.querySelectorAll(
    'button[data-filter="trans-area"]'
  );
  const valueChainSegments = container.querySelectorAll(
    'button[data-filter="vcs"]'
  );
  const benchmarkTopics = container.querySelectorAll(
    'button[data-filter="benchmark_topic"]'
  );

  const listingCount = container.querySelector(LISTING_COUNT_SELECTOR);
  const noResults = container.querySelector(NO_RESULTS_SELECTOR);
  const listingContainer = container.querySelector(LIST_SELECTOR);

  const items = container.querySelectorAll(ITEMS_SELECTOR);
  const itemCount = items.length;

  const filters = {};

  const itemMatchesFilters = (item) => {
    return Object.entries(filters).every(([filterType, filterValue]) => {
      return filterFunctions[filterType](item, filterValue);
    });
  };

  const hideItem = (item) => item.setAttribute("hidden", "");
  const showItem = (item) => item.removeAttribute("hidden");

  const hideNoResults = () => noResults.setAttribute("hidden", "");
  const showNoResults = () => noResults.removeAttribute("hidden");

  const toggleButton = (button) => {
    button.setAttribute(
      "aria-pressed",
      !(button.getAttribute("aria-pressed") === "true")
    );
    button.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const updateListingCount = (amount) => {
    if (!listingCount) {
      return;
    }
    // eslint-disable-next-line
    listingCount.innerHTML =
      amount === itemCount ? `${itemCount}` : `${amount} of ${itemCount} items`;
  };

  const hideListing = () => {
    listingContainer.setAttribute("aria-hidden", "true");
  };

  const showListing = () => {
    listingContainer.setAttribute("aria-hidden", "false");
  };

  const moveToStartPosition = () => {
    const { top } = container.getBoundingClientRect();
    if (top > 0) {
      return;
    }
    document.documentElement.style.scrollBehavior = "auto";
    document.documentElement.scrollTop = top + window.pageYOffset;
    document.documentElement.style.scrollBehavior = "";
  };

  const refreshItems = () => {
    hideListing();
    const matchingItems = [...items].filter((item) => itemMatchesFilters(item));
    window.setTimeout(() => {
      [...items].map(hideItem);
      matchingItems.map(showItem);
      updateListingCount(matchingItems.length);
      moveToStartPosition();
      if (!matchingItems.length) {
        showNoResults();
      } else {
        hideNoResults();
      }
      showListing();
    }, 300);
  };

  const queryChanged = (el) => (e) => {
    filters.query = el.value;
    refreshItems();
  };

  const transAreasChanged = (clickedArea, areas) => (e) => {
    toggleButton(clickedArea);
    filters.transAreas = [...areas]
      .filter((area) => area.getAttribute("aria-pressed") === "true")
      .map((area) => area.getAttribute("data-value"));
    refreshItems();
  };

  const valueChainSegmentsChanged = (clickedSegment, segments) => (e) => {
    toggleButton(clickedSegment);
    filters.valueChainSegments = [...segments]
      .filter((segment) => segment.getAttribute("aria-pressed") === "true")
      .map((segment) => segment.getAttribute("data-value"));
    refreshItems();
  };

  const benchmarkTopicChanged = (clicked, buttons) => (e) => {
    toggleButton(clicked);
    filters.benchmarkTopics = [...buttons]
      .filter((button) => button.getAttribute("aria-pressed") === "true")
      .map((button) => button.getAttribute("data-value"));
    refreshItems();
  };

  const industryChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.industry = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const countryChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.country = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const stakeholderGroupChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.stakeholderGroup = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const newsTypeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.newsType = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const impactTypeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.impactType = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const researchTypeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.researchType = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const teamChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.team = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const regionChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.region = select.value;
    refreshItems();
    select.blur(); // @TODO this should actualy be handled by `:focus-visible`.
  };

  const uncheckTransAreas = () => {
    [...transAreas].map((area) => area.setAttribute("aria-pressed", false));
  };

  const uncheckValueChainSegments = () => {
    [...valueChainSegments].map((area) => {
      area.setAttribute("aria-pressed", false);
    });
  };

  const uncheckBenchmarkTopics = () => {
    [...benchmarkTopics].map((benchmark) => {
      benchmark.setAttribute("aria-pressed", false);
    });
  };

  const checkTransArea = (transAreaName) => {
    const button = container.querySelector(
      `button[data-slug="${transAreaName}"]`
    );
    if (!button) {
      return;
    }
    button.click();
  };

  const checkValueChainSegment = (segmentName) => {
    const button = container.querySelector(
      `button[data-slug="${segmentName}"]`
    );
    if (!button) {
      return;
    }
    button.click();
  };

  const checkBenchmarkTopic = (benchmarkName) => {
    const button = container.querySelector(
      `button[data-slug="${benchmarkName}"]`
    );
    if (!button) {
      return;
    }
    button.click();
  };

  const selectTransAreaByHash = (hash) => {
    const hashToCheck = "#transarea=";
    if (!hash.startsWith(hashToCheck)) {
      return;
    }
    const transArea = hash.substring(hashToCheck.length);
    uncheckTransAreas();
    checkTransArea(transArea);
  };

  const selectValueChainSegmentByHash = (hash) => {
    const hashToCheck = "#vcs=";
    if (!hash.startsWith(hashToCheck)) {
      return;
    }
    const segment = hash.substring(hashToCheck.length);
    uncheckValueChainSegments();
    checkValueChainSegment(segment);
  };

  const selectBenchmarkTopicByHash = (hash) => {
    const hashToCheck = "#benchmark=";
    if (!hash.startsWith(hashToCheck)) {
      return;
    }
    const benchmark = hash.substring(hashToCheck.length);
    uncheckBenchmarkTopics();
    checkBenchmarkTopic(benchmark);
  };

  const hashChangeHandler = (e) => {
    selectTransAreaByHash(window.location.hash);
    selectValueChainSegmentByHash(window.location.hash);
    selectBenchmarkTopicByHash(window.location.hash);
  };

  return {
    init() {
      if (searchInput) {
        searchInput.addEventListener(
          "input",
          debounce(queryChanged(searchInput), 300)
        );
      }
      [...transAreas].map((area) => {
        area.addEventListener("click", transAreasChanged(area, transAreas));
      });
      [...valueChainSegments].map((segment) => {
        segment.addEventListener(
          "click",
          valueChainSegmentsChanged(segment, valueChainSegments)
        );
      });
      [...benchmarkTopics].map((button) => {
        button.addEventListener(
          "click",
          benchmarkTopicChanged(button, benchmarkTopics)
        );
      });
      if (industrySelect) {
        industrySelect.addEventListener(
          "change",
          industryChanged(industrySelect)
        );
      }
      if (countrySelect) {
        countrySelect.addEventListener("change", countryChanged(countrySelect));
      }
      if (stakeholderGroupSelect) {
        stakeholderGroupSelect.addEventListener(
          "change",
          stakeholderGroupChanged(stakeholderGroupSelect)
        );
      }
      if (newsTypeSelect) {
        newsTypeSelect.addEventListener(
          "change",
          newsTypeChanged(newsTypeSelect)
        );
      }
      if (impactTypeSelect) {
        impactTypeSelect.addEventListener(
          "change",
          impactTypeChanged(impactTypeSelect)
        );
      }
      if (researchTypeSelect) {
        researchTypeSelect.addEventListener(
          "change",
          researchTypeChanged(researchTypeSelect)
        );
      }
      if (teamSelect) {
        teamSelect.addEventListener("change", teamChanged(teamSelect));
      }
      if (regionSelect) {
        regionSelect.addEventListener("change", regionChanged(regionSelect));
      }
      selectTransAreaByHash(window.location.hash);
      selectValueChainSegmentByHash(window.location.hash);
      selectBenchmarkTopicByHash(window.location.hash);
      window.addEventListener("hashchange", hashChangeHandler);
    },
  };
};

export const enhancer = (container) => {
  const filterableList = FilterableList(container);
  filterableList.init();
};
