const toggleContent = (el) => {
  const target = el.parentNode.parentNode.querySelector(el.getAttribute('data-target'));

  target.setAttribute('aria-hidden', target.getAttribute('aria-hidden') === 'false');
};

const updateLabelText = (el) => {
  const labelText = el.querySelector(".js-button-label");

  if (el.getAttribute('aria-expanded') === 'false') {
    labelText.innerText = "Read more";
  } else {
    labelText.innerText = "Read less";
  }
};

export const handler = (el, e) => {
  el.setAttribute('aria-expanded', el.getAttribute('aria-expanded') === 'false');
  toggleContent(el);
  updateLabelText(el);
};
