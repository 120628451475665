// Import libraries and polyfills
import "url-polyfill";
import Promise from "promise-polyfill";
import "whatwg-fetch";
import "classlist-polyfill";
import { handle, enhance } from "@grrr/hansel";
import rafPolyfill from "./polyfills/request-animation-frame";
import "./polyfills/array-findindex";

// Import functions that are executed on DOMready regardless of DOM
import { onDomReady } from "./modules/ready";
import { enhancer as scrollListener } from "./modules/scroll-listener";
import { enhancer as responsive } from "./modules/responsive";
import { default as disableHoverStylesOnScroll } from "./modules/disable-hover-styles-on-scroll";

// Import handlers
import { handler as accessibleCollapser } from "./modules/accessible-collapser";
import { handler as classToggler } from "./modules/class-toggler";
import { handler as collapsibleItemHandler } from "./modules/collapsible-item";
import { handler as collapsibleText } from "./modules/collapsible-text";
import { handler as cookieBarAccept } from "./modules/cookie-bar";
import { handler as gtmEventHandler } from "./modules/gtm-event";
import { handler as printPage } from "./modules/print-page";
import { toggleHandler as siteNavToggler } from "./modules/site-nav";

import { handler as scrollTo } from "./modules/scroll-to";
import { mapInit as alliesOverviewMapInit } from "./modules/allies-overview-map";
import { mapInit as companyOverviewMapInit } from "./modules/company-overview-map";
import { play as lazyVideoEmbedPlay } from "./modules/lazy-video-embed";

// Import enhancers
import { enhancer as collapsibleItemEnhancer } from "./modules/collapsible-item";
import { enhancer as companyListing } from "./modules/company-listing";
import { enhancer as filterableList } from "./modules/filterable-list";
import { enhancer as companyMap } from "./modules/company-map";
import { enhancer as cookieBar } from "./modules/cookie-bar";
import { enhancer as filterableRankingTable } from "./modules/filterable-ranking-table";
import { enhancer as gtmEventEnhancer } from "./modules/gtm-event";
import { enhancer as headroomEnhancer } from "./modules/headroom";
import { enhancer as imageScroller } from "./modules/image-scroller";
import { enhancer as newsletterSignup } from "./modules/newsletter-signup";
import { enhancer as objectFit } from "./modules/object-fit";
import { enhancer as alliesOverviewMap } from "./modules/allies-overview-map";
import { enhancer as overviewMap } from "./modules/company-overview-map";
import { enhancer as siteNav } from "./modules/site-nav";
import { enhancer as sticky } from "./modules/sticky";
import { enhancer as tabList } from "./modules/tab-list";
import { enhancer as tooltip } from "./modules/tooltip";
import { enhancer as transformationAreaSlider } from "./modules/transformation-area-slider";
import { enhancer as worldMap } from "./modules/world-map";

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  if (typeof window.Promise === "undefined") {
    window.Promise = Promise;
  }
};

const main = () => {
  executeOnReady();
  handle(document.documentElement, {
    accessibleCollapser,
    classToggler,
    collapsibleItemHandler,
    collapsibleText,
    alliesOverviewMapInit,
    companyOverviewMapInit,
    cookieBarAccept,
    gtmEventHandler,
    printPage,
    scrollTo,
    siteNavToggler,
    lazyVideoEmbedPlay,
  });
  enhance(document.documentElement, {
    collapsibleItemEnhancer,
    companyListing,
    alliesOverviewMap,
    companyMap,
    cookieBar,
    filterableList,
    filterableRankingTable,
    gtmEventEnhancer,
    headroomEnhancer,
    imageScroller,
    newsletterSignup,
    objectFit,
    overviewMap,
    siteNav,
    sticky,
    tabList,
    tooltip,
    transformationAreaSlider,
    worldMap,
  });
};

onDomReady(main);
