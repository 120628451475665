/* eslint-disable operator-linebreak */
import { preventingDefault } from "@grrr/utils";

/**
 * Generic tablist handler.
 */
const TabList = (container) => {
  const tabListButton = container.querySelector("button");
  // TODO This mumblejumble is because I have added the new tab-list partial that includes a button.
  // Should refactor this so it can be html agnostic.
  const tabsContainer =
    container.querySelector(".js-tab-list-options") || container;
  const tabListSelected = container.querySelector(".js-tab-list__selected");
  const tabs = [...container.querySelectorAll('[role="tab"]')];

  const items = [];

  const collapseTabList = () => {
    tabsContainer.setAttribute("aria-expanded", "false");
    tabsContainer.classList.toggle("is-expanded");
  };

  const expandTabList = () => {
    tabsContainer.setAttribute("aria-expanded", "true");
    tabsContainer.classList.toggle("is-expanded");
  };

  const toggleTabList = (e) => {
    if (tabsContainer.getAttribute("aria-expanded")) {
      expandTabList();
    } else {
      collapseTabList();
    }
  };

  const tabClickHandler = (e) => {
    items.forEach((item) => {
      const match = item.tab === e.currentTarget;
      item.tab.setAttribute("aria-selected", match);
      item.panel.setAttribute("aria-hidden", !match);
      if (tabListSelected && match) {
        tabListSelected.innerText = item.tab.firstElementChild.innerText;
      }
      collapseTabList();
    });
  };

  return {
    init() {
      tabs.forEach((tab) => {
        items.push({
          tab,
          panel: document.querySelector(tab.getAttribute("href")),
        });
        if (tabListButton) {
          tabListButton.addEventListener("click", toggleTabList);
        }
        tab.addEventListener("click", preventingDefault(tabClickHandler));
      });
    },
  };
};

export const enhancer = (container) => {
  const tabList = TabList(container);
  tabList.init();
};
