/* eslint-disable operator-linebreak */
const LIST_SELECTOR = ".js-filterable-ranking-table";
const ITEMS_SELECTOR = ".js-item";
const GROUP_ROW_SELECTOR = ".js-group-row";
const GROUP_AMOUNT_SELECTOR = ".js-group-amount";
const GROUP_TOGGLE_BUTTON_SELECTOR = ".js-group-toggler";
const LISTING_COUNT_SELECTOR = ".js-listing-count";
const NO_RESULTS_SELECTOR = ".js-no-results";

const filterFunctions = {
  region(item, value) {
    return value ? item.getAttribute("data-region") === value : true;
  },
  industry(item, value) {
    return value ? item.getAttribute("data-industry") === value : true;
  },
  scope(item, value) {
    return value ? item.getAttribute("data-scope") === value : true;
  },
  company_type(item, value) {
    return value ? item.getAttribute("data-company-type").split(",").includes(value) : true;
  },
};

function FilterableRankingTable(container) {
  const filters = {};

  const regionSelect = container.querySelector('select[data-filter="region"]');
  const industrySelect = container.querySelector('select[data-filter="industry"]');
  const scopeSelect = container.querySelector('select[data-filter="scope"]');
  const companyTypeSelect = container.querySelector('select[data-filter="company-type"]');
  const listingContainer = container.querySelector(LIST_SELECTOR);
  const listingCount = container.querySelector(LISTING_COUNT_SELECTOR);
  const noResults = container.querySelector(NO_RESULTS_SELECTOR);
  const items = container.querySelectorAll(ITEMS_SELECTOR);
  const itemCount = items.length;
  const groupRows = container.querySelectorAll(GROUP_ROW_SELECTOR);

  const openGroup = (groupRow) => {
    const toggleButton = groupRow.querySelector(GROUP_TOGGLE_BUTTON_SELECTOR);
    toggleButton.setAttribute("aria-expanded", "true");
    groupRow.nextElementSibling.classList.add("is-expanded");
  };

  const closeGroup = (groupRow) => {
    const toggleButton = groupRow.querySelector(GROUP_TOGGLE_BUTTON_SELECTOR);
    toggleButton.setAttribute("aria-expanded", "false");
    groupRow.nextElementSibling.classList.remove("is-expanded");
  };

  const updateGroupRows = () => {
    [...groupRows].map((groupRow) => {
      const groupAmountElement = groupRow.querySelector(GROUP_AMOUNT_SELECTOR);
      const visibleItems = groupRow.nextElementSibling.querySelectorAll(
        `${ITEMS_SELECTOR}[aria-hidden="false"]`
      );
      groupAmountElement.innerHTML =
        visibleItems.length === 1
          ? `${visibleItems.length} company`
          : `${visibleItems.length} companies`;

      if (visibleItems.length) {
        openGroup(groupRow);
      } else {
        closeGroup(groupRow);
      }
    });
  };

  const itemMatchesFilters = (item) => {
    return Object.entries(filters).every(([filterType, filterValue]) => {
      return filterFunctions[filterType](item, filterValue);
    });
  };

  const updateListingCount = (amount) => {
    if (!listingCount) {
      return;
    }
    listingCount.innerHTML = amount === itemCount ? `${itemCount}` : `${amount} of ${itemCount}`;
  };

  const hideListing = () => {
    listingContainer.setAttribute("aria-hidden", "true");
  };

  const showListing = () => {
    listingContainer.setAttribute("aria-hidden", "false");
  };

  const hideItem = (item) => item.setAttribute("aria-hidden", "true");
  const showItem = (item) => item.setAttribute("aria-hidden", "false");

  const hideRankDashes = (item) => {
    const rankingValue = item.querySelector(".ranking-value");
    if (rankingValue && rankingValue.innerText === "-") {
      rankingValue.innerText = item.getAttribute("data-ranking");
      rankingValue.setAttribute("data-ranking-dashed", "true");
    }
  };

  const showRankDashes = (item) => {
    const rankingValue = item.querySelector(".ranking-value");
    if (rankingValue && rankingValue.getAttribute("data-ranking-dashed")) {
      rankingValue.innerText = "-";
    }
  };

  const updateRankDashes = (matchingItems, itemsAreFiltered) => {
    if (itemsAreFiltered) {
      matchingItems.map(hideRankDashes);
    } else {
      matchingItems.map(showRankDashes);
    }
  };

  const hideNoResults = () => {
    if (!noResults) {
      return;
    }
    noResults.setAttribute("hidden", "");
  };
  const showNoResults = () => {
    if (!noResults) {
      return;
    }
    noResults.removeAttribute("hidden");
  };

  const refreshItems = () => {
    hideListing();
    const matchingItems = [...items].filter((item) => itemMatchesFilters(item));
    const itemsAreFiltered = matchingItems.length !== items.length;
    window.setTimeout(() => {
      [...items].map(hideItem);
      matchingItems.map(showItem);
      updateRankDashes(matchingItems, itemsAreFiltered);
      updateListingCount(matchingItems.length);
      updateGroupRows();
      if (!matchingItems.length) {
        showNoResults();
      } else {
        hideNoResults();
      }
      showListing();
    }, 300);
  };

  const regionChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.region = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const scopeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.scope = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const companyTypeChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.company_type = select.value;
    refreshItems();
    select.blur(); // @TODO this should actually be handled by `:focus-visible`.
  };

  const industryChanged = (select) => (e) => {
    select.setAttribute("value", select.value);
    filters.industry = select.value;
    refreshItems();
    select.blur();
  };

  return {
    init: () => {
      if (regionSelect) {
        regionSelect.addEventListener("change", regionChanged(regionSelect));
      }
      if (industrySelect) {
        industrySelect.addEventListener("change", industryChanged(industrySelect));
      }
      if (scopeSelect) {
        scopeSelect.addEventListener("change", scopeChanged(scopeSelect));
      }
      if (companyTypeSelect) {
        companyTypeSelect.addEventListener("change", companyTypeChanged(companyTypeSelect));
      }
    },
  };
}

export const enhancer = (container) => {
  const filterableRankingTable = FilterableRankingTable(container);
  filterableRankingTable.init();
};
