/*
 * Polyfill for object-fit
 * @see: https://github.com/constancecchen/object-fit-polyfill
 */

/* global objectFitPolyfill */
import 'objectFitPolyfill';

export const enhancer = (elm) => {
  objectFitPolyfill(elm);
};
