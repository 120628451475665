import { map } from '@grrr/utils';

const createTooltip = el => {
  const tooltip = document.createElement('div');
  const tooltipContent = el.getAttribute('data-tooltip');
  const tooltipInner = document.createElement('span');
  tooltipInner.classList.add('tooltip__inner');
  tooltipInner.innerHTML = tooltipContent;
  tooltip.appendChild(tooltipInner);
  tooltip.classList.add('tooltip', 'js-tooltip');
  tooltip.setAttribute('role', 'tooltip');

  window.requestAnimationFrame(() => {
    if (tooltip.getBoundingClientRect().left < 0) {
      tooltip.classList.add('tooltip--align-left');
    }
    if (tooltip.getBoundingClientRect().right > document.documentElement.clientWidth) {
      tooltip.classList.add('tooltip--align-right');
    }
  });
  return tooltip;
};

const removeTooltip = tooltip => {
  if (!tooltip.parentNode) {
    return;
  }
  tooltip.parentNode.removeChild(tooltip);
};

const activateTooltip = el => {
  const activeTooltips = document.querySelectorAll('.js-tooltip');
  map(activeTooltip => {
    if (!activeTooltip) {
      return;
    }
    removeTooltip(activeTooltip);
  }, [...activeTooltips]);
  el.appendChild(createTooltip(el));
};

const deactivateTooltip = el => {
  const activeTooltip = el.querySelector('.js-tooltip');
  if (!activeTooltip) {
    return;
  }
  setTimeout(() => removeTooltip(activeTooltip), 600);
};

export const enhancer = el => {
  ['mouseenter', 'focusin'].forEach(type => {
    el.addEventListener(type, () => activateTooltip(el));
  });
  ['mouseleave', 'focusout'].forEach(type => {
    el.addEventListener(type, () => deactivateTooltip(el));
  });
};
