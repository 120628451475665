/* eslint-disable no-unused-vars */

import Flickity from 'flickity';
import { getDocWidth } from './responsive';

const SLIDER_SELECTOR = '.js-slider';
const ITEM_SELECTOR = '.js-item';
const ARROW_SELECTOR = '.flickity-prev-next-button';
const SCROLL_TO_ATTRIBUTE = 'data-scroll-to';

export const enhancer = container => {

  const slider = container.querySelector(SLIDER_SELECTOR);

  const flkty = new Flickity(slider, {
    autoPlay: false,
    cellAlign: 'left',
    contain: true,
    freeScroll: false,
    pageDots: false,
    accessibility: false,
    arrowShape: 'M33.777 17.899L1.856 50.42l31.922 32.52 4.281-4.203L13.21 53.42H100v-6H13.21L38.06 22.1 33.776 17.9zM10.79 49.886v1.068l-.524-.534.524-.534z',
    on: {
      ready: () => slider.classList.add('is-ready'),
    },
  });

  // Quick and dirty fix to disable the 'next' button when the last slide is
  // visible. Due to accessibility Flickity allows click to the last slide,
  // while that is already visible (but not 'selected').
  flkty.on('scroll', e => {
    const next = container.querySelector('.flickity-prev-next-button.next');
    const last = flkty.getLastCell();
    next.disabled = last.element.getBoundingClientRect().right < getDocWidth();
  });

  // Disable prev/next button focus, since all items are focusable.
  // Also move them a node up, so we can position them better.
  [...container.querySelectorAll(ARROW_SELECTOR)].forEach(button => {
    button.setAttribute('tabindex', '-1');
    button.parentNode.parentNode.appendChild(button);
  });

  // Attach `focusin` event listener on cards, which bubbles (as opposed to `focus`).
  // This selects the cell which currently has focus, allowing for interaction with
  // the actual content of the cells.
  [...container.querySelectorAll(ITEM_SELECTOR)].forEach(item => {
    item.addEventListener('focusin', e => {
      const match = flkty.cells.find(cell => cell.element === item);
      const index = flkty.cells.findIndex(cell => cell === match);
      flkty.select(index, false, true);
    });
  });
};
