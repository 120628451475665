import { htmlToElement } from '@grrr/utils';

/**
* Lazy-load video's after the user has chosen to play it.
*/
const PLAYING_CLASS = 'is-playing-video';

const getVideoEmbed = ({ type, id }) => {
  switch (type) {
    case 'youtube':
      return `
        <iframe src="https://www.youtube.com/embed/${id}?color=white&rel=0&autoplay=1&modestbranding=1&showinfo=0" width="1280" height="720" frameborder="0" allowfullscreen></iframe>
      `;
    case 'vimeo':
      return `
        <iframe src="https://player.vimeo.com/video/${id}?autoplay=1&color=01cbe1&title=0&byline=0&portrait=0" width="1280" height="720" frameborder="0" allowfullscreen></iframe>
      `;
    case 'facebook':
      return `
        <div class="fb-video-wrapper">
          <div class="fb-video" data-href="https://www.facebook.com/facebook/videos/${id}/" data-width="552" data-show-text="false" data-autoplay="true" data-allowfullscreen="true"></div>
        </div>
      `;
    default:
      return ``;
  }
};

const embedVideo = el => {
  const html = el.getAttribute('data-video-html');
  const type = el.getAttribute('data-video-type');
  const id = el.getAttribute('data-video-id');
  if (!html && (!type || !id)) {
    return;
  }

  const embed = html || getVideoEmbed({ type, id });
  const container = htmlToElement(`
    <div class="video-embed js-video-container">${embed}</div>
  `);

  const embedToElement = document.querySelector(
    `#${el.getAttribute('data-video-embed-element')}`
  );
  const classToElement = document.querySelector(
    `#${el.getAttribute('data-video-class-element')}`
  );

  if (embedToElement) {
    embedToElement.appendChild(container);
    classToElement.classList.add(PLAYING_CLASS);
  } else {
    el.parentNode.classList.add(PLAYING_CLASS);
    el.parentNode.appendChild(container);
  }
};

export const play = (el, e) => {
  e.preventDefault();
  embedVideo(el);
};
