import { map } from '@grrr/utils';
import { matchesBreakpoint } from './responsive';

const HTML_ACTIVE_CLASS = 'has-active-site-nav';
const NAV_SELECTOR = '.js-site-nav';
const TOGGLE_BUTTON_SELECTOR = '.js-site-nav-toggle';
const SUBMENU_TOGGLE_SELECTOR = '.js-submenu-toggle';

const toggleSiteNav = ({ shouldHide }) => {
  const nav = document.querySelector(NAV_SELECTOR);
  const button = document.querySelector(TOGGLE_BUTTON_SELECTOR);

  nav.setAttribute('data-expanded', !shouldHide);
  button.setAttribute('aria-expanded', !shouldHide);
  document.documentElement.classList.toggle(HTML_ACTIVE_CLASS, !shouldHide);
};

const SiteNavSubmenus = nav => {
  const toggles = nav.querySelectorAll(SUBMENU_TOGGLE_SELECTOR);

  const alignSubmenu = submenu => {
    const screenWidth = document.body.offsetWidth;
    if (submenu.getBoundingClientRect().right > screenWidth) {
      submenu.classList.add('is-right-aligned');
    }
  };

  const toggleSubmenu = ({ toggle, shouldShow }) => {
    const submenu = document.getElementById(toggle.getAttribute('aria-controls'));
    submenu.setAttribute('aria-hidden', !shouldShow);
    toggle.setAttribute('aria-expanded', shouldShow);
    toggle.parentNode.setAttribute('data-expanded', shouldShow);
    if (shouldShow) {
      alignSubmenu(submenu);
    }
  };

  const toggleActiveState = ({ toggle, shouldShow }) => {
    shouldShow = typeof shouldShow !== 'undefined'
      ? shouldShow
      : toggle.getAttribute('aria-expanded') !== 'true';

    map(t => {
      toggleSubmenu({ toggle: t, shouldShow: false });
    }, [...toggles]);

    toggleSubmenu({ toggle, shouldShow });
  };

  const toggleClickHandler = toggle => e => toggleActiveState({
    toggle,
    shouldShow: matchesBreakpoint('medium') ? true : undefined,
  });

  const toggleMouseOverHandler = toggle => e => toggleActiveState({
    toggle,
    shouldShow: true,
  });

  const toggleMouseLeaveHandler = toggle => e => toggleActiveState({
    toggle,
    shouldShow: false,
  });

  return {
    init() {
      map(toggle => {
        toggle.addEventListener('click', toggleClickHandler(toggle));
        if (matchesBreakpoint('medium')) {
          const toggleContainer = toggle.parentNode;
          toggleContainer.addEventListener('mouseover', toggleMouseOverHandler(toggle));
          toggleContainer.addEventListener('mouseleave', toggleMouseLeaveHandler(toggle));
        }
      }, [...toggles]);
    },
  };
};

export const toggleHandler = (button, e) => {
  e.preventDefault();
  toggleSiteNav({ shouldHide: button.getAttribute('aria-expanded') === 'true' });
};

export const enhancer = nav => {
  const submenus = SiteNavSubmenus(nav);
  submenus.init();
};
