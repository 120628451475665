import { map } from '@grrr/utils';

export const handler = (anchor, e) => {
  e.preventDefault();

  const expanded = anchor.getAttribute('aria-expanded') === 'true';
  const content = document.querySelector(`#${anchor.getAttribute('aria-controls')}`);

  content.setAttribute('aria-hidden', expanded);
  anchor.setAttribute('aria-expanded', !expanded);
  anchor.blur();
};

export const enhancer = el => {
  const url = new URL(window.location.href);
  const items = [...el.querySelectorAll('div')];

  /**
    * Find matching identifier and scroll expanded content into view
    */
  map(item => {
    const itemLink = item.querySelector('a');
    const itemContent = item.querySelector('dd');

    if (url.searchParams.has(itemLink.getAttribute('data-title'))) {
      itemLink.setAttribute('aria-expanded', true);
      itemContent.setAttribute('aria-hidden', false);
      itemLink.scrollIntoView();
    }
  }, items);
};
