import { parseJson } from '@grrr/utils';

const composeGeoJsonFeatures = (locations) => {
  return locations.map(location => {
    return {
      type: 'Feature',
      properties: {
        title: location.title,
        country: location.place,
        ranking: location.ranking,
        link: location.link,
      },
      geometry: {
        type: "Point",
        coordinates: [location.lng, location.lat],
      },
    };
  });
};

export const enhancer = (container) => {
  const url = container.getAttribute('data-mapbox-js');
  if (!url) {
    console.warn('Missing Mapbox URL.');
    return;
  }

  const locations = parseJson(container.getAttribute('data-locations'));
  const mapboxStyle = parseJson(container.getAttribute('data-mapbox-style'));

  const addSources = map => {
    map.addSource('locations', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: composeGeoJsonFeatures(locations),
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
  };

  const addLayers = map => {
    map.addLayer({
      id: 'markers',
      type: 'circle',
      source: 'locations',
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": mapboxStyle.marker_color, // TODO: this color should change depending on the benchmark
        "circle-radius": 12,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
      },
    });

    map.addLayer({
      id: "clusters",
      type: "circle",
      source: 'locations',
      filter: ["has", "point_count"],
      paint: {
        "circle-color": '#FFFFFF',
        "circle-radius": 27,
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "locations",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-size": 19,
        "text-font": ["Open Sans Bold"],
      },
      paint: {
        "text-color": "#0B274B",
      },
    });
  };

  const addHandlers = map => {
    map.on('click', 'markers', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the §copy being pointed to.
      while (Math.abs(e.lngLat - coordinates[0] > 180)) {
        coordinates[0] += e.lngLat > coordinates[0] ? 360 : -360;
      }
    });

    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });

      const clusterId = features[0].properties.cluster_id;
      map.getSource('locations').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      });
    });
  };

  const initMapbox = () => {
    const map = new window.mapboxgl.Map({
      container: 'overview-map',
      style: mapboxStyle.style,
      center: [0.1, 0.1],
      zoom: 1,
      maxZoom: 7,
      renderWorldCopies: false,
      scrollZoom: false,
      attributionControl: false,
    });
    window.companyOverviewMap.map = map;

    map.addControl(new window.mapboxgl.AttributionControl({
      compact: true,
    }), 'top-left');

    map.addControl(new window.mapboxgl.NavigationControl({
      showCompass: false,
    }), 'bottom-right');

    map.on('load', () => {
      addSources(map);
      addLayers(map);
      addHandlers(map);
    });
  };

  const init = () => {
    window.loadJS(url, () => {
      window.mapboxgl.accessToken = 'pk.eyJ1Ijoid29ybGRiYSIsImEiOiJjazFieDh6aTUwYXc1M2lvNnZwdWUyaDAxIn0.hvNlT95aX3xtGlbK78bNHA';
      initMapbox();
    });
    window.companyOverviewMap.active = true;
  };

  window.companyOverviewMap = {
    active: false,
    init,
  };
};

export const mapInit = (el, e) => {
  if (window.companyOverviewMap.active) {
    return;
  }
  window.companyOverviewMap.init();
};
